import React, { FC } from 'react'
import {
  Box,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  offerData: UserGlobal.Deal
}

const OfferPreviewCardMobile: FC<Props> = ({ offerData }) => {
  const { t } = useTranslation()

  return <>
    <SimpleGrid
      bg="transparent"
      mt="0"
      width="100%"
      templateColumns="35fr 65fr"
      position="relative"
      spacingX="m"
    >
      <Box flexShrink={0}>
        <Image
          borderRadius="10px"
          src={offerData.vehicle?.image?.path}
          alt={offerData.vehicle.name}
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml="1px">
        <Text
          fontFamily="Montserrat-SemiBold"
          fontSize="1rem"
          color="baseDark"
        >
          {offerData.vehicle.name}
        </Text>
        <Text fontFamily="Montserrat-Regular" color="baseNeutral.700" fontSize="0.875rem">
          {offerData.vehicle.version}
        </Text>
      </Box>
    </SimpleGrid>

    <Box mt="s" textAlign="left">
      <Text color="baseDark" fontFamily="Montserrat-Regular" fontSize="0.875rem" display="inline-block">
        {t('nltCustomizeOfferModalStepsComponent:duration')}
      </Text>
      <Text ml="s" color="baseDark" fontFamily="Montserrat-SemiBold" fontSize="0.875rem" display="inline-block">
        {offerData.contractLength} {t('nltCustomizeOfferModalStepsComponent:months')}
      </Text>
    </Box>
    <Box textAlign="left">
      <Text color="baseDark" fontFamily="Montserrat-Regular" fontSize="0.875rem" display="inline-block">
        {t('nltCustomizeOfferModalStepsComponent:kmPerYear')}
      </Text>
      <Text ml="s" color="baseDark" fontFamily="Montserrat-SemiBold" fontSize="0.875rem" display="inline-block">
        {offerData.annualMileage.toLocaleString('it-IT')}
      </Text>
    </Box>
    <Box textAlign="left">
      <Text color="baseDark" fontFamily="Montserrat-Regular" fontSize="0.875rem" display="inline-block">
        {t('nltCustomizeOfferModalStepsComponent:deposit')}
      </Text>
      <Text ml="s" color="baseDark" fontFamily="Montserrat-SemiBold" fontSize="0.875rem" display="inline-block">
        {offerData.deposit.toLocaleString('it-IT')}€
      </Text>
    </Box>

    <Box position="absolute" right="5" bottom="7">
      <Text
        fontFamily="Montserrat-SemiBold"
        fontSize="1.5rem"
        color="baseDark"
        align="right"
      >
        € {offerData.monthlyRate.toLocaleString('it-IT')}
      </Text>
      <Text
        fontFamily="Montserrat-Medium"
        color="baseNeutral.700"
        fontSize="0.625rem"
        align="right"
      >
        {t('nltCustomizeOfferModalStepsComponent:monthlyTaxExluded')}
      </Text>
    </Box>
  </>
}

export default OfferPreviewCardMobile
