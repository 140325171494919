import React, { FC } from 'react'
import {
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { LimitedOfferBadge } from 'src/uikit'
import OfferPreviewCard from 'src/components/OfferPreviewCard/'

interface Props {
  offerData: UserGlobal.Deal
}

const OfferPreviewCardContainer: FC<Props> = ({ offerData }) => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({ base: true, md: false })

  return <Box
    bg="baseWhite"
    borderRadius="10px"
    p="l"
    width={isMobile ? '100%' : '66%'}
    mx="auto"
    mt="xl"
    boxShadow="md"
    position="relative"
  >
    <LimitedOfferBadge marginTop="-35px" marginBottom="10px" textAlign="center">
      {t('resumeOfferModalContainer:expiringOfferBadge')}
    </LimitedOfferBadge>

    <OfferPreviewCard offerData={offerData} />
  </Box>
}

export default OfferPreviewCardContainer
