import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import ResumeOfferModal from 'src/components/ResumeOfferModal'
import { toggleCustomizeModal } from 'src/redux/nlt-offer/actions'

const ResumeOfferModalContainer: FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cookies, setCookie] = useCookies();
  const user = useSelector((state: Store.RootState) => state.user?.profile)
  const customizedOffer = useSelector((state: Store.RootState) => state.user?.profile?.deal)
  const customizeModalIsOpen = useSelector((state: Store.RootState) => state.nlt.customizeModalIsOpen)

  const closeHanlder = () => {
    setCookie('resumeOfferModalHidden', true, { path: '/' })
    onClose()
  }

  useEffect(() => {
    if (customizedOffer && !cookies.resumeOfferModalHidden) {
      onOpen()
    }
  })

  if (!user || !customizedOffer || !customizedOffer.deal || customizeModalIsOpen) {
    return null
  }

  return <ResumeOfferModal
    customizedOffer={customizedOffer}
    title={t('resumeOfferModalContainer:title', { username: user.firstName })}
    subTitle={t('resumeOfferModalContainer:subTitle', { car: customizedOffer.vehicle.name })}
    description={t('resumeOfferModalContainer:description')}
    descsecureDataTextription={t('resumeOfferModalContainer:secureDataText')}
    closeText={t('resumeOfferModalContainer:closeText')}
    closeTextMobile={t('resumeOfferModalContainer:closeTextMobile')}
    submitText={t('resumeOfferModalContainer:submitText')}
    submitAction={() => {
      if (customizedOffer.qualification.isQualified) {
        router.push(`/caricamento-documenti`)
      } else {
        router.push(`/noleggio-a-lungo-termine/${customizedOffer.offerCode}`).then(() => {
          closeHanlder();
          dispatch(toggleCustomizeModal({ step: 3 }));
        })
      }
    }}
    isOpen={isOpen}
    onClose={closeHanlder}
  />
}

export default ResumeOfferModalContainer
