import React, { FC } from 'react'
import { SecondaryButton } from 'src/uikit'
import { LockIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import OfferPreviewCardContainer from './OfferPreviewCardContainer'

interface Props {
  customizedOffer: UserGlobal.Deal
  title: string
  subTitle: string
  description: string
  descsecureDataTextription: string
  closeText: string
  closeTextMobile: string
  submitText: string
  submitAction(): any
  isOpen: boolean
  onClose: any
}

const ResumeOfferModal: FC<Props> = ({
  customizedOffer,
  title,
  subTitle,
  description,
  descsecureDataTextription,
  closeText,
  closeTextMobile,
  submitText,
  submitAction,
  isOpen,
  onClose
}) => {

  if (!customizedOffer) {
    return null
  }

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor="baseNeutral.100"
        minWidth={isMobile ? null : '890px'}
        borderRadius="10px"
        px={isMobile ? 'xl' : 'xxl'}
      >
        <ModalHeader
          borderBottom="1px solid #dadde1"
          fontFamily="Montserrat-SemiBold"
          px="0"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton mt="1" />
        <ModalBody m="0" px="0" pb="xl" pt="l">
          <Text fontFamily="Montserrat-SemiBold" fontSize={{ base: '1rem', md: '1.125rem' }} color="baseDark" mb="l">
            {subTitle}
          </Text>
          <Text fontFamily="Montserrat-Regular" fontSize={{ base: '0.875rem', md: '1rem' }} color="baseDark">
            {description}
          </Text>
          <OfferPreviewCardContainer offerData={customizedOffer} />
          <Text fontFamily="Montserrat-Medium" fontSize="0.625rem" color="baseDark" textAlign="center" mt="s">
            <LockIcon /> {descsecureDataTextription}
          </Text>

          {!isMobile &&
            <Flex width="66%" mx="auto" mt="xl">
              <Box>
                <Button
                  variant="ghost"
                  fontFamily="Montserrat-Medium"
                  color="primaryGreen.800"
                  fontSize="1rem"
                  textTransform="uppercase"
                  fontWeight="normal"
                  onClick={onClose}
                >
                  {closeText}
                </Button>
              </Box>
              <Spacer />
              <Box>
                <SecondaryButton
                  title={submitText}
                  action={submitText.toUpperCase()}
                  fontSize="1rem"
                  onClick={submitAction}
                />
              </Box>
            </Flex>
          }
        </ModalBody>
        {isMobile &&
          <ModalFooter px="0" mx="0" justifyContent="space-between">
            <Button
              variant="ghost"
              fontFamily="Montserrat-Medium"
              color="primaryGreen.800"
              fontSize="1rem"
              textTransform="uppercase"
              fontWeight="normal"
              onClick={onClose}
            >
              {closeTextMobile}
            </Button>
            <SecondaryButton
              title={submitText}
              action={submitText.toUpperCase()}
              fontSize="1rem"
              onClick={submitAction}
              width="inherit"
            />
          </ModalFooter>
        }
      </ModalContent>
    </Modal>
  )
}

export default ResumeOfferModal
