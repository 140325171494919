import React, { FC } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import OfferPreviewCardDesktop from './Desktop'
import OfferPreviewCardMobile from './Mobile'

interface Props {
  offerData: UserGlobal.Deal
}

const OfferPreviewCard: FC<Props> = ({ offerData }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return isMobile ? <OfferPreviewCardMobile offerData={offerData} /> : <OfferPreviewCardDesktop offerData={offerData} />
}

export default OfferPreviewCard
